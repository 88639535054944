import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p>Thanks for your interest for catering with Amberly Local! We'll get back to you as soon as possible.</p>
          <p>In the meantime, feel free to stop by in person and check out our food and drink menus!</p>
        </div>
      </div>
    </section>
  </Layout>
)
